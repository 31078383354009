var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cp-resume" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c("b-form-group", { attrs: { label: "Adresse" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.chargingPoint.address))
                ])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("b-form-group", { attrs: { label: "Visibilité" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(
                    _vm._s(
                      _vm.chargingPoint.private
                        ? "Mes invités"
                        : "Tout le monde"
                    )
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-form-group", { attrs: { label: "PRIX" } }, [
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.price) + "€")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }