<template>
  <div class="cp-resume">
    <b-row>
      <b-col cols="8">
        <b-form-group label="Adresse">
          <span class="text-uppercase">{{ chargingPoint.address }}</span>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Visibilité">
          <span class="text-uppercase">{{
            chargingPoint.private ? 'Mes invités' : 'Tout le monde'
          }}</span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="PRIX">
          <span class="price">{{ price }}&euro;</span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'charging-point-resume',
  props: {
    chargingPoint: { type: Object, required: true }
  },
  computed: {
    price() {
      return this.chargingPoint.price ? this.chargingPoint.price : 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.cp-resume {
  font-weight: $font-weight-bold;
  font-size: 9px;
  color: $dark;

  fieldset {
    margin-bottom: 0;

    /deep/ legend {
      color: #a0a0a0;
      padding-bottom: 0;
      font-size: 9px !important;
      text-transform: uppercase;
    }
  }

  .price {
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    color: $dark;

    &::before {
      display: inline-block;
      padding: 0px 5px 0px 0px;
      vertical-align: -2px;
      content: url('../../assets/img/icon-yellow-plus-sign.png');
    }
  }
}
</style>
